import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Hero from "components/hero"
import About from "components/about"


const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>{data.globals.site_title}</title>
        <meta name="description" content={data.globals.site_meta_description} />
      </Helmet>
      <Hero intro={data.about.intro} />
      <About content={data.about.content} />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    about: directusAbout {
      intro
      content
    }
    globals: directusGlobal {
      site_title
      site_meta_description
    }
  }
`