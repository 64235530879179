import React from "react"
import styled from "styled-components"
import { useGlobals } from "components/context"
import Button from "components/button"
import IconArrowRight from "components/icons/arrowRight"



const About = ({ content }) => {

  const { setAlbumNavOpened } = useGlobals()

  return (
    <StyledAbout>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Button 
        htmlFor="navtoggle"
        as="label"
        className="desktop-hidden"
        onClick={(e) => { e.preventDefault(); setAlbumNavOpened(true)}}
      >
        <span>Browse the Albums</span> <IconArrowRight />
      </Button>
    </StyledAbout>
  )
}

export default About


const StyledAbout = styled.article`
  position: relative;
  z-index: 3;
  font-family: var(--font-serif);
  padding: calc(1.5 * var(--padding) ) 0 calc(3 * var(--padding));
  background-color: var(--color-background);
  > div {
    margin-bottom: 2em;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
  }
  p:first-of-type {
    font-size: 1.25em;
    margin-top: 0;
  }
  p {
    font-size: 1em;
  }
  strong {
    font-family: var(--font-sans);
    font-weight: 600;
    font-size: 0.875em;
    background-color: var(--color-white);
  }
  @media ( min-width: 48em ) {
    p:first-of-type {
      font-size: 1.375em;
    }
    p {
      font-size: 1.125em;
    }
  }
  @media ( min-width: 60em ) {
    padding: calc(1.75 * var(--padding)) calc(3 * var(--padding)) calc(3 * var(--padding)) 0;
    &::before {
      right: 0;
      margin-left: 0;
      left: auto;
      margin-right: -30%;
    }
  }
`
export { StyledAbout }